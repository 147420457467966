// i18next-extract-mark-ns-start accept-payments-dental-practice

import { ContactSalesButton } from "components/ContactSalesButton";
import { Content } from "components/Content";
import { List, OrderedList } from "components/List";
import "keen-slider/keen-slider.min.css";
import { Section, SectionActions, SectionHeader } from "components/Section";
import { SEO } from "components/SEO";
import { SignUpButton } from "components/SignUpButton";
import { graphql } from "gatsby";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

import styled from "styled-components";
import { Flex } from "components/Flex";
import React from "react";
import { DownloadOnAppleStore, IosDownloadLink } from "components/DownloadOnAppleStore";
import { AndroidDownloadLink, DownloadOnGooglePlay } from "components/DownloadOnGooglePlay";
import Bold from "components/Bold";
import { AnchorLink } from "components/AnchorLink";
import { MoneiVideoSection } from "components/landings/MoneiVideoSection";
import { CompatibleWith } from "components/monei-pay/CompatibleWith";
import IndexBackground from "components/monei-pay/IndexBackground";
import { BlogLink } from "components/links/Blog";
import { InternalPageLink, PricingLink } from "components/links/Pages";
import CardsContainer from "components/CardsContainer";
import { Card } from "components/Card";
import cards from "images/credit-cards.svg";
import mobile_payment from "images/mobile_payment.svg";
import money from "images/money.svg";
import dashboard from "images/exchange-platform.svg";
import person from "images/person.svg";
import check_green from "images/check_green.svg";
import { SignUpLink } from "components/links/Other";
import { StyledDiv } from "components/StyledSx";
import { Background } from "components/Background";
import { QrGroup } from "components/monei-pay/QrGroup";
import { RotatedScreenshots } from "components/monei-pay/RotatedScreenshots";
import { MainTitleContent } from "components/monei-pay/MainTitleContent";
import { SupportLink } from "components/links/Support";
import clock from "images/clock.svg";

const CardWrapper = styled.div`
  max-width: 230px;
`;

const CardImage = styled.img`
  position: absolute;
`;

const CheckGreen = styled.img`
  margin: 4px 8px 0 0;
`

const AcceptPaymentsDentalPractice: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        title={t('Accept Payments in your Dental Practice')}
        description={t(
          'Use your phone to accept payments in your dental practice. Charge patients via credit card, Google Pay, Apple Pay, PayPal, and Bizum. Get MONEI Pay ››'
        )}
      />

      <IndexBackground>
        <Content>
          <Section columnLg>
            <MainTitleContent sx={{maxWidth: {all: '590px', lg: '100%'}}}>
              <SectionHeader underline tagName="h1">
                <Trans>The easiest way to accept payments in your dental practice</Trans>
              </SectionHeader>
              <Trans parent="p">
                Managing a dental practice isn’t simple — you have to help patients, schedule
                appointments, make calls, and the list goes on. Accepting payments in your dental
                practice should be the least of your worries. That’s why it’s important to use a
                functional and safe system to charge your patients. Whether you have your own dental
                practice, work in a bigger office, or are part of a dental clinic, MONEI Pay is the
                perfect solution to help you accept cards, Google Pay, Apple Pay, PayPal, and Bizum
                in your dental practice.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Get MONEI Pay</Trans>
                </SignUpButton>
                <ContactSalesButton />
                <CompatibleWith />
              </SectionActions>
            </MainTitleContent>
            <MoneiVideoSection />
          </Section>
        </Content>
      </IndexBackground>
      <Content>
        <Section sx={{paddingTop: '20px'}}>
          <Flex direction="column" justifyContent="center" alignItems="center">
            <SectionHeader underline style={{marginTop: '10px', textAlign: 'center'}}>
              <Trans>Advantages of accepting payments in your dental practice <br/>with MONEI Pay</Trans>
            </SectionHeader>
            <p style={{textAlign: 'center'}}>
              <Trans>
                With MONEI Pay’s app for dental payments you can forget about unreliable dental POS
                terminals, and start using your phone, tablet, or computer to accept instant
                payments in your dental practice or anywhere else.
              </Trans>
            </p>

            <CardsContainer sx={{marginTop: '100px'}}>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={cards} alt="" style={{top: -40, width: 80}} />
                <CardWrapper>
                  <Trans>
                    Accept more{' '}
                    <InternalPageLink slug="payment-methods">payment methods</InternalPageLink> like{' '}
                    <InternalPageLink slug="payment-methods/credit-cards">cards</InternalPageLink>,{' '}
                    <InternalPageLink slug="payment-methods/google-pay">
                      Google Pay
                    </InternalPageLink>
                    ,{' '}
                    <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                    <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>, and{' '}
                    <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={money} alt="" style={{top: -48}} />
                <CardWrapper>
                  <Trans>
                    Save up to 50% on <PricingLink>transaction fees</PricingLink>
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={mobile_payment} alt="" style={{top: -55, width: 100}} />
                <CardWrapper>
                  <Trans>
                    Use the computer, phone, or tablet you already have instead of a dataphone
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={dashboard} alt="" style={{top: -55, width: 100}} />
                <CardWrapper>
                  <Trans>
                    <SupportLink slug="/articles/8797620433937">Customize</SupportLink> your dental
                    payment page
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={clock} alt="" style={{top: -65, width: 85}} />
                <CardWrapper>
                  <Trans>Instantly process dental patient payments</Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={person} alt="" style={{top: -60, width: 90}} />
                <CardWrapper>
                  <Trans>
                    Offer patients a{' '}
                    <BlogLink slug="frictionless-payments">
                      frictionless payment experience
                    </BlogLink>
                  </Trans>
                </CardWrapper>
              </Card>
            </CardsContainer>
          </Flex>
        </Section>
        <Flex alignItems="center" direction="column">
          <Trans parent="p">
            👇 <Bold>Download your mobile payment app for retail today</Bold> 👇
          </Trans>
          <Flex>
            <div style={{marginRight: '16px'}}>
              <DownloadOnAppleStore />
            </div>
            <DownloadOnGooglePlay />
          </Flex>
        </Flex>
      </Content>
      <Background style={{marginTop: '50px'}}>
        <Content>
          <Section sx={{paddingTop: {sm: '100px'}, alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
              <SectionHeader style={{fontSize: '2rem'}} underline>
                <Trans>How does MONEI Pay for dentists work?</Trans>
              </SectionHeader>
              <Trans parent="p">
                Accepting payments without a dental POS system is easy and secure. Here’s how it
                works:
              </Trans>
              <OrderedList>
                <Trans parent="li">
                  <SignUpLink>Create your MONEI account</SignUpLink>
                </Trans>
                <Trans parent="li">
                  <SupportLink slug="/articles/6167527597457">Download</SupportLink> the MONEI Pay
                  app to your smartphone or tablet (you can also accept payments at{' '}
                  <AnchorLink href="https://pay.monei.com">pay.monei.com</AnchorLink>{' '}
                  from any device/browser)
                </Trans>
                <Trans parent="li">
                  Enter the invoice amount to{' '}
                  <SupportLink slug="/articles/4417709618193">
                    create digital QR code payments
                  </SupportLink>{' '}
                </Trans>
                <Trans parent="li">
                  Have your patient scan the QR code to pay or enter their mobile number to{' '}
                  <SupportLink slug="/articles/12253203762449">send a payment request</SupportLink>
                </Trans>
                <Trans parent="li">
                  Patient selects their preferred{' '}
                  <SupportLink slug="/articles/4417709486737">payment method</SupportLink>
                </Trans>
                <Trans parent="li">Patient finishes the payment with the tap of a button</Trans>
                <Trans parent="li">
                  Funds are instantly deposited into your business bank account
                </Trans>
              </OrderedList>
              <Trans parent="p">
                💡<Bold>Do you want to bill your dental patients later?</Bold> You can also use
                MONEI pay to{' '}
                <BlogLink slug="email-payment-link">send payment links via email</BlogLink>, SMS, or
                WhatsApp with{' '}
                <InternalPageLink slug="features/pay-by-link">Pay By Link</InternalPageLink>.
              </Trans>
              <SignUpButton variant="dark">
                <Trans>Try MONEI Pay</Trans>
              </SignUpButton>
            </div>
            <RotatedScreenshots />
          </Section>
        </Content>
      </Background>

      <Content>
        <Section reverseOnMobile centered sx={{paddingTop: '40px'}}>
          <QrGroup />
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>Save time in your dental office with MONEI Pay</Trans>
            </SectionHeader>
            <Trans parent="p">Try MONEI Pay now, with no commitment:</Trans>
            <List listStyle="none" className="flex-list">
              <Trans parent="li">
                <CheckGreen src={check_green} width={16} height={16} />︎
                <span>Open your MONEI account</span>
              </Trans>
              <Trans parent="li">
                <CheckGreen src={check_green} width={16} height={16} />
                <span>
                  Download the <AndroidDownloadLink>Android</AndroidDownloadLink> or{' '}
                  <IosDownloadLink>iOS</IosDownloadLink> mobile payment app on as many devices as
                  you need
                </span>
              </Trans>
              <Trans parent="li">
                <CheckGreen src={check_green} width={16} height={16} />︎︎
                <span>
                  Start generating digital QR code payments and/or sending payment requests for your
                  dental services
                </span>
              </Trans>
            </List>
            <StyledDiv sx={{display: {all: 'inline', sm: 'none'}}}>
              <Trans parent="p">
                👈 <Bold>Scan any QR code to experience MONEI Pay.</Bold>
              </Trans>
            </StyledDiv>
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </div>
        </Section>
      </Content>
    </>
  );
};

export default AcceptPaymentsDentalPractice;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "accept-payments-dental-practice"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
